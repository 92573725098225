export const plUITranslations = {
  MUIGrid: {
    applyFilters: "Zastosuj Filtry",
    close: "Zamknij",
    deleteRowsModal: {
      cancel: "Anuluj",
      confirm: "Usuń",
      message: "Czy na pewno chcesz usunąć zaznaczone wiersze?",
      title: "Usuń wiersze",
    },
    edit: "Edytuj",
    filters: "Filtry",
    linkedMap: "Mapa połączeń",
    refresh: "Odśwież",
    remove: "Usuń",
    removeRowConfirmation: "Czy na pewno chcesz usunąć ten wiersz?",
    removeSelectedRowsConfirmation: "Czy na pewno chcesz usunąć zaznaczone wiersze?",
    reorder: "Zmień Kolejność",
    resetFilters: "Wyczyść Filtry",
    stopReorder: "Zakończ zmienianie kolejności",
  },
  Drawer: {
    DrawerActions: {
      serverError: "Błąd na serwerze",
    },
    closeButtonLabel: "Zamknij",
  },
  Scripts: {
    drawer: {
      default: "Domyślny",
    },
  },
  Steps: {
    NewStep: {
      variableAssignment: {
        newVariableInfo: "nowa zmienna będzie utworzona",
        selectOrCreateVariable: "Utwórz lub wybierz zmienną",
        selectVariable: "Wybierz zmienną",
        variableType: "Typ zmiennej",
      },
    },
  },
  Status: {
    EN: "EN",
    PL: "PL",
    active: "Aktywny",
    authenticated: "Zautoryzowany",
    authenticating: "Uwierzytelnianie",
    changeCircle: "Zmiana Koła",
    closed: "Zamknięty",
    completed: "Zakończony",
    constant: "Constant",
    datasource: "Datasource",
    development: "Rozwój",
    document: "Dokument",
    draft: "Szkic",
    error: "Błąd",
    expired: "Wygasł",
    finished: "Zakończony",
    iconCodeBadge: "Kod",
    iconDeviceHubBadge: "Urządzenie",
    iconForwardBadge: "Przesłać",
    inProgress: "W Trakcie",
    inactive: "Nieaktywny",
    linked: "Powiązany",
    mutable: "Zmienna",
    none: "Brak",
    normal: "Normal",
    notAuthenticated: "Nieuwierzytelniony",
    notCompleted: "Nieukończony",
    o: "O",
    other: "Inny",
    paused: "Wstrzymany",
    pending: "Uruchomiony",
    production: "Produkcja",
    published: "Opublikowany",
    readyForReview: "Gotowy do Przeglądu",
    readyToTest: "Gotowy do Testów",
    removed: "Usunięte",
    running: "Uruchomiony",
    sequence: "Sequence",
    success: "Sukces",
    terminated: "Zakończony",
    test: "Test",
    uat: "Test Akceptacji Użytkownika",
    untested: "Niesprawdzony",
    variable: "Zmienna",
    video: "Wideo",
    warning: "Ostrzeżenie",
    warningError: "Ostrzeżenie",
  },
  Runner: {
    stopRecording: "Zatrzymaj nagrywanie",
    startRecording: "Rozpocznij nagrywanie",
    stopPlaying: "Zatrzymaj odtwarzanie",
    startPlaying: "Rozpocznij odtwarzanie",
    reset: "Resetuj",
    inspect: "Inspekcja",
    recorder: "RECORDER",
    player: "PLAYER",
    cannotSwitchWhileActive: "Nie można przełączyć podczas aktywności",
    cannotSwitchToRecorderForPublishedScripts:
      "Nie można przełączyć na rejestrator dla opublikowanych skryptów",
    changeModeTo: "Zmień tryb na",
    collapsePanel: "Zwiń panel",
    expandPanel: "Rozwiń panel",
    continuous: "Ciągły",
    default: "domyślny",
    stepByStep: "Krok po kroku",
    playbackModeSettings: "Ustawienia trybu odtwarzania",
    inspectingOnlyAvailableWhileRecording: "Inspekcja dostępna tylko podczas nagrywania",
    stopInspecting: "Zatrzymaj inspekcję",
    startInspecting: "Rozpocznij inspekcję",
    save: "Zapisz",
    stop: "STOP",
    play: "ODTWÓRZ",
    rec: "REC",
    pleaseWaitForPageToLoad: "Proszę poczekać na załadowanie strony",
    searchSteps: "Szukaj kroków",
    refresh: "Odśwież",
    stepName: "Nazwa Kroku",
    duration: "Czas trwania",
  },
};
