"use client";
import { BOOL_VALUE } from "@ea/shared_types/next/ea.enums";
import { EA_UTILS, getBoolValue } from "@ea/shared_types/next/ea.utils";
import { ReactNode, useContext } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import {
  Box,
  BoxProps,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  FormLabelProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "../materialUI";
import { EditableContext } from "./utils";

type FormRadioGroupProps = {
  name: string;
  label?: ReactNode;
  options: { label: ReactNode; value: string | number | boolean }[];
  dataTestidHelperText?: string;
  dataTestidRadioGroup: string;
  formControlProps?: FormControlProps;
  radioGroupProps?: RadioGroupProps;
  formLabelProps?: FormLabelProps;
  formControlLabelProps?: Partial<FormControlLabelProps>;
  horizontal?: boolean;
  handleBooleanValue?: boolean;
  defaultValue?: string | number | boolean;
  boxProps?: BoxProps;
};

export const FormRadioGroup = ({
  name,
  options,
  dataTestidRadioGroup,
  dataTestidHelperText,
  radioGroupProps,
  formControlLabelProps,
  handleBooleanValue,
  defaultValue,
  horizontal,
  boxProps,
}: FormRadioGroupProps) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const fieldError = EA_UTILS.object.getNestedValue(errors, name) as FieldError | undefined;
  const editable = useContext(EditableContext);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => {
        const { onChange: AdditionalOnChange, ...rest } = radioGroupProps || {};
        return (
          <RadioGroup
            onChange={(event, value) => {
              if (handleBooleanValue) {
                onChange(getBoolValue(value as BOOL_VALUE));
              } else {
                onChange(event, value);
              }
              AdditionalOnChange?.(event, value);
            }}
            onBlur={onBlur}
            value={value !== undefined ? value : null}
            data-testid={dataTestidRadioGroup}
            {...rest}
          >
            <Box flexDirection={horizontal ? "row" : "column"} display={"flex"} {...boxProps}>
              {options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option.value}
                  //The readonly attribute is not applicable to radio groups
                  control={
                    <Radio color="secondary" disabled={!editable} name={`${option.value}`} />
                  }
                  label={option.label}
                  data-testid={`${dataTestidRadioGroup}-${option.value}`}
                  {...formControlLabelProps}
                />
              ))}
            </Box>
            {fieldError && (
              <FormHelperText
                data-testid={!dataTestidHelperText && `${dataTestidRadioGroup}-helper-text`}
                error
              >
                {fieldError.message}
              </FormHelperText>
            )}
          </RadioGroup>
        );
      }}
    />
  );
};
