"use client";

import { AssignedVariableType } from "@ea/shared_types/next/ea.enums";
import { OptionType } from "@ea/shared_types/next/ea.types";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormAutocomplete, FormHiddenInput } from "../../../components/forms";
import { DataTestIds } from "../../../dataTestIds";
import { useTranslations } from "../../../translations/useTranslations";
import { VariableAssignmentFormData } from "../types";

type NameSelectionProps = {
  variableOptions: any; // WORK; typings
};

export const NameSelection = ({ variableOptions }: NameSelectionProps) => {
  const { control, setValue } = useFormContext<VariableAssignmentFormData>();
  const { t } = useTranslations("Steps");
  const [isNewVariable, setIsNewVariable] = useState<boolean>(false);

  const assignedVariableIdValue = useWatch({
    control,
    name: "value.code.assignedVariableId",
  });

  const type = useWatch({ control, name: "value.code.assignedVariableType" });

  useEffect(() => {
    const option = assignedVariableIdValue
      ? variableOptions.find((option) => option.value === assignedVariableIdValue)
      : null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (option) setValue("variable" as any, option?.value);
  }, [assignedVariableIdValue, variableOptions]);

  return (
    <>
      <FormHiddenInput
        name="value.code.assignedVariableId"
        dataTestid={DataTestIds.FORM_SELECT_VARIABLE}
      />
      <FormAutocomplete
        name={"variable"}
        textFieldProps={{
          required: true,
        }}
        label={
          type === AssignedVariableType.GLOBAL
            ? t("NewStep.variableAssignment.selectVariable")
            : t("NewStep.variableAssignment.selectOrCreateVariable")
        }
        options={variableOptions}
        dataTestid={DataTestIds.FORM_INPUT_NAME}
        disableClearable={false}
        helperText={isNewVariable ? t("NewStep.variableAssignment.newVariableInfo") : null}
        freeSolo
        disabled={!type}
        defaultValue={
          assignedVariableIdValue
            ? variableOptions.find((option) => option.value === assignedVariableIdValue)
            : null
        }
        onInputChange={(_, value) => {
          const option = variableOptions.find((option) => option.label === value);
          if (option) {
            setIsNewVariable(false);
            setValue("value.code.assignedVariableId", option.value);
          } else {
            setIsNewVariable(true);
            setValue("value.code.assignedVariableId", value);
          }
        }}
        additionalOnChange={(selectedOption) => {
          if (selectedOption) {
            setValue("value.code.assignedVariableId", (selectedOption as OptionType).value);
          }
        }}
        sx={{
          ml: 2,
          width: "100%",
        }}
      />
    </>
  );
};
