export const enUITranslations = {
  MUIGrid: {
    applyFilters: "Apply Filters",
    close: "Close",
    deleteRowsModal: {
      cancel: "Cancel",
      confirm: "Delete",
      message: "Are you sure you want to remove the selected rows?",
      title: "Delete Rows",
    },
    edit: "Edit",
    filters: "Filters",
    linkedMap: "Linked Map",
    refresh: "Refresh",
    remove: "Remove",
    removeRowConfirmation: "Are you sure you want to remove this row?",
    removeSelectedRowsConfirmation: "Are you sure you want to remove selected rows?",
    reorder: "Reorder",
    resetFilters: "Reset Filters",
    stopReorder: "Stop reordering",
  },
  Drawer: {
    DrawerActions: {
      serverError: "Server Error",
    },
    closeButtonLabel: "Close",
  },
  Scripts: {
    drawer: {
      default: "Default",
    },
  },
  Steps: {
    NewStep: {
      variableAssignment: {
        newVariableInfo: "new variable will be created",
        selectOrCreateVariable: "Select or create variable",
        selectVariable: "Select variable",
        variableType: "Variable Type",
      },
    },
  },
  Status: {
    EN: "EN",
    PL: "PL",
    active: "Active",
    authenticated: "Authenticated",
    authenticating: "Authenticating",
    changeCircle: "ChangeCircle",
    closed: "Closed",
    completed: "Completed",
    constant: "Constant",
    datasource: "Datasource",
    development: "Development",
    document: "Document",
    draft: "Draft",
    error: "Error",
    expired: "Expired",
    finished: "Finished",
    iconCodeBadge: "Code",
    iconDeviceHubBadge: "DeviceHub",
    iconForwardBadge: "Forward",
    inProgress: "In Progress",
    inactive: "Inactive",
    linked: "Linked",
    mutable: "Variable",
    none: "None",
    normal: "Normal",
    notAuthenticated: "Not Authenticated",
    notCompleted: "Not Completed",
    o: "O",
    other: "Other",
    paused: "Paused",
    pending: "Pending",
    production: "Production",
    published: "Published",
    readyForReview: "Ready for Review",
    readyToTest: "Ready to Test",
    removed: "Removed",
    running: "Running",
    sequence: "Sequence",
    success: "Success",
    terminated: "Terminated",
    test: "Test",
    uat: "User Acceptance Test",
    untested: "Untested",
    variable: "Variable",
    video: "Video",
    warning: "Warning",
    warningError: "Warning",
  },
  Runner: {
    stopRecording: "Stop Recording",
    startRecording: "Start Recording",
    stopPlaying: "Stop Playing",
    startPlaying: "Start Playing",
    reset: "Reset",
    inspect: "Inspect",
    recorder: "RECORDER",
    player: "PLAYER",
    cannotSwitchWhileActive: "Cannot switch while active",
    cannotSwitchToRecorderForPublishedScripts: "Cannot switch to recorder for published scripts",
    changeModeTo: "Change mode to",
    continuous: "Continuous",
    default: "default",
    stepByStep: "Step by Step",
    playbackModeSettings: "Playback mode settings",
    collapsePanel: "Collapse panel",
    expandPanel: "Expand panel",
    inspectingOnlyAvailableWhileRecording: "Inspecting is only available while recording",
    stopInspecting: "Stop Inspecting",
    startInspecting: "Start Inspecting",
    save: "Save",
    stop: "STOP",
    play: "PLAY",
    rec: "REC",
    pleaseWaitForPageToLoad: "Please wait for the page to load",
    searchSteps: "Search steps",
    refresh: "Refresh",
    stepName: "Step Name",
    duration: "Duration",
  },
};
