"use client";
import { StyledComponent } from "@emotion/styled";
import { Box, BoxProps, Button, ButtonProps, styled } from "@mui/material";
import { Theme } from "@mui/material/styles";

export const BarWrapper: StyledComponent<BoxProps & { theme?: Theme }, object, object> = styled(
  Box,
)(() => ({
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-end",
  flexWrap: "wrap",
}));

export const StyledActionButton: StyledComponent<
  ButtonProps & { theme?: Theme; disabled?: boolean },
  object,
  object
> = styled(Button)(({ theme, disabled }) => ({
  color: disabled ? theme.palette.secondary[200] : theme.palette.secondary[500],
  marginRight: theme.spacing(1),
  width: "max-content",
}));
