"use client";
import { EA_UTILS } from "@ea/shared_types/next/ea.utils";
import { ClearIcon } from "@mui/x-date-pickers";
import React, { useContext, useState } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  TextFieldVariants,
} from "../materialUI";
import { EditableContext } from "./utils";
type FormNumberFieldProps = TextFieldProps & {
  name: string;
  size?: "small" | "medium";
  variant?: TextFieldVariants;
  fullWidth?: boolean;
  dataTestid: string;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  readOnly?: boolean;
  allowNegativeNumbers?: boolean;
  clearable?: boolean;
  minValue?: number;
  maxValue?: number;
};

export const FormNumberField = React.forwardRef(
  (
    {
      name,
      size = "small",
      fullWidth = true,
      variant = "outlined",
      dataTestid,
      iconStart,
      iconEnd,
      sx,
      readOnly,
      allowNegativeNumbers = false,
      minValue = 0,
      maxValue = undefined,
      clearable = false,
      ...props
    }: FormNumberFieldProps,
    ref,
  ) => {
    const {
      control,
      setValue,
      watch,

      formState: { errors },
    } = useFormContext();
    const [isHovering, setIsHovering] = useState(false);
    const fieldError = EA_UTILS.object.getNestedValue(errors, name) as FieldError | undefined;

    const inputProps: { startAdornment?: React.ReactNode; endAdornment?: React.ReactNode } = {};

    if (iconStart) {
      inputProps.startAdornment = (
        <InputAdornment position="start" sx={(theme) => ({ color: theme.palette.secondary[500] })}>
          {iconStart}
        </InputAdornment>
      );
    }
    if (iconEnd) {
      inputProps.endAdornment = (
        <InputAdornment position="end" sx={(theme) => ({ color: theme.palette.secondary[500] })}>
          {iconEnd}
        </InputAdornment>
      );
    }

    const contextReadOnly = !useContext(EditableContext);
    const editable = !(readOnly ?? contextReadOnly);

    const defaultNumberProps = !allowNegativeNumbers
      ? {
          inputProps: {
            min: minValue,
            max: maxValue,
          },
        }
      : {};

    const onKeyDown = !allowNegativeNumbers
      ? (e) => {
          // prevent from typing negative number
          if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
            e.preventDefault();
          }
        }
      : undefined;

    return (
      <Controller
        name={name}
        control={control}
        rules={{}}
        defaultValue={""}
        render={({ field }) => {
          const currentValueInForm = watch(name);
          if (field.value === "" && currentValueInForm !== null) {
            // in case of initial render with empty value, we have tu set null in form
            setValue(name, null);
          }
          return (
            <TextField
              size={size}
              variant={variant}
              type={"number"}
              fullWidth={fullWidth}
              error={!!fieldError}
              helperText={fieldError?.message || ""}
              data-testid={dataTestid}
              sx={{
                ...sx,
                "& .MuiIconButton-root": {
                  visibility: isHovering && field.value ? "visible" : "hidden",
                  transition: "visibility 0.2s",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                "& input[type=number]::-webkit-outer-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
                "& input[type=number]::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
              }}
              {...props}
              {...field}
              onChange={(e) => {
                field.onChange(e);
                if (e.target.value === "") {
                  setValue(name, null, { shouldDirty: true });
                }
              }}
              value={field.value === null ? "" : field.value}
              inputProps={{
                readOnly: !editable,
                ...props.inputProps,
              }}
              onKeyDown={onKeyDown}
              InputProps={{
                endAdornment: clearable ? (
                  <InputAdornment position="end">
                    {field.value && editable && isHovering && (
                      <IconButton
                        aria-label="clear input"
                        onClick={() => {
                          setValue(name, null);
                        }}
                        edge="end"
                        size="small"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    )}
                    {iconEnd}
                  </InputAdornment>
                ) : undefined,
                ...inputProps,
                ...defaultNumberProps,
                readOnly: !editable,
                ...props.InputProps,
              }}
              classes={{
                root: editable ? "" : "MuiReadOnly",
              }}
              focused={false}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            />
          );
        }}
      />
    );
  },
);
FormNumberField.displayName = "FormNumberField";
