"use client";
import { RunnerStep } from "@ea/shared_types/next/ea.runner.next.types";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormInputsWrapper,
  FormSubmitButton,
  FormTextField,
  FormWrapper,
  Grid,
  Typography,
} from "../../../components";
import { DataTestIds } from "../../../dataTestIds";
import { NameSelection } from "./NameSelection";
import { VariableTypeSelection } from "./VariableTypeSelection";

type CreateInspectStepDialog = {
  open: boolean;
  step: RunnerStep;
  handleClose: (data: { ok: boolean; result?: RunnerStep }) => void;
  variableOptions: any; // WORK: type;
};

export const CreateInspectStepDialog = ({
  open,
  step,
  variableOptions,
  handleClose,
}: CreateInspectStepDialog) => {
  // WORK: translations
  // Date test id
  // WORK:

  const methods = useForm<RunnerStep>({
    defaultValues: { ...step },
    mode: "onChange",
    resolver: zodResolver(z.object({})),
  });

  return (
    <Dialog open={open} onClose={() => handleClose({ ok: false })}>
      <DialogTitle withCloseIcon onClose={() => handleClose({ ok: false })}>
        <Typography fontWeight={500} variant="h4" color="neutral600">
          Create Inspect Step
        </Typography>
      </DialogTitle>
      <FormWrapper
        defaultValues={{}}
        onSubmit={() => {}}
        dataTestid={DataTestIds.GANTT_FORM_TASK_DETAILS}
        methods={methods}
      >
        <DialogContent>
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item xs={12}>
              <FormTextField
                name="label"
                label={"Step Name"}
                fullWidth
                placeholder={"Step Name"}
                required
                dataTestid={DataTestIds.GANTT_FORM_TASK_DETAILS_NAME}
              />
            </Grid>

            <Grid item xs={12}>
              <FormInputsWrapper direction="row" alignItems="start">
                <VariableTypeSelection />
                <NameSelection variableOptions={variableOptions} />
              </FormInputsWrapper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", gap: "8px" }}>
          <Button variant="text" onClick={() => handleClose({ ok: false })}>
            Cancel
          </Button>
          <FormSubmitButton dataTestid={DataTestIds.SCRIPTS.ACTIONS.RECORD} variant="contained">
            Create
          </FormSubmitButton>
        </DialogActions>
      </FormWrapper>
    </Dialog>
  );
};
