import { AssignedVariableType } from "@ea/shared_types/types";
import { capitalizeFirstLetter } from "../../utils/text";

export const useVariableTypeOptions = () => {
  const options = Object.values(AssignedVariableType).map((type) => ({
    label: capitalizeFirstLetter(type),
    value: type,
  }));

  return options;
};
