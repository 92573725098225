"use client";
import { useContext } from "react";
import { translations } from ".";
import { TranslationContext } from "./TranslationContext";
import { FlattenKeys, TranslationFunction, TranslationKeys } from "./translations.types";

export const useTranslations = <K extends keyof TranslationKeys>(namespace: K) => {
  const context = useContext(TranslationContext);

  if (!context) {
    throw new Error("useTranslations must be used within a TranslationProvider");
  }

  const { t: contextT, locale } = context;

  const t: TranslationFunction<K> = <TKey extends FlattenKeys<TranslationKeys[K]>>(
    key: TKey,
    params?: Record<string, unknown>,
  ) => {
    const stringKey = String(key);
    const result = contextT(namespace)(stringKey as TKey, params);

    if (result === `${namespace}.${stringKey}`) {
      const keys = stringKey.split(".");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let defaultTranslation: any = translations[locale]?.[namespace];
      for (const k of keys) {
        defaultTranslation = defaultTranslation?.[k];
        if (defaultTranslation === undefined) break;
      }
      return defaultTranslation || `${namespace}.${stringKey}`;
    }
    return result;
  };

  return { t, locale };
};
