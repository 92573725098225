"use client";

import { FormAutocomplete } from "../../../components/forms";
import { DataTestIds } from "../../../dataTestIds";
import { useTranslations } from "../../../translations/useTranslations";
import { useVariableTypeOptions } from "../formOptions";

export const VariableTypeSelection = () => {
  const { t } = useTranslations("Steps");

  const variableTypeOptions = useVariableTypeOptions();

  return (
    <FormAutocomplete
      name={"value.code.assignedVariableType"}
      textFieldProps={{ required: true }}
      showHelperText={false}
      label={t("NewStep.variableAssignment.variableType")}
      options={variableTypeOptions}
      dataTestid={DataTestIds.FORM_SELECT_VARIABLE_TYPE}
      sx={{ width: "100%" }}
    />
  );
};
