import React from "react";
import { BarWrapper } from "./ActionsBar.styles";

type ActionsBarProps = {
  children: React.ReactNode;
};

export const ActionsBar = ({ children }: ActionsBarProps) => {
  return <BarWrapper>{children}</BarWrapper>;
};
